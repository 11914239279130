<template>
  <div class="ui-pl-6 ui-pr-3 ui-py-4 ui-flex ui-mb-2 album-item c-bg-white">
    <icon name="drag" color="grey-400" />
    <div class="ui-flex-grow">
      <div class="c-text-b2">
        {{ album.title }}
      </div>
      <div class="c-text-b3 c-text-gray-300 ui-inline">
        <icon
          :name="album.is_published ? 'check' : 'error'"
          :color="album.is_published ? 'green' : 'red'"
          filled
          width="16"
          class="ui-mr-1 ui-inline"
        />
        <span v-if="album.is_published">Álbum publicado</span>
        <span v-else>Álbum não publicado</span>
        <span>
          • Atualizado em:
          {{ (album.updated_at || album.created_at) | formatDate }}</span
        >
      </div>
    </div>

    <c-dropdown-action placement="right-center">
      <template #label>
        <icon name="kebab" color="grey-400" />
      </template>
      <c-options
        v-if="!album.is_published"
        class="hover:c-bg-gray-100 hover:c-text-gray-400 c-text-gray-400"
        @click="() => publish()"
      >
        <div class="ui-flex">
          <icon name="eye" class="ui-mr-2" /> Publicar álbum
        </div>
      </c-options>
      <c-options
        v-else
        class="hover:c-bg-gray-100 hover:c-text-gray-400 c-text-gray-400"
        @click="() => unpublish()"
      >
        <div class="ui-flex">
          <icon name="eye-invisible" class="ui-mr-2" /> Despublicar álbum
        </div>
      </c-options>
    </c-dropdown-action>
  </div>
</template>
<script>
import { Icon, CDropdownAction, COptions } from "@estrategiahq/coruja-web-ui";
import { events } from "../constants";
export default {
  components: {
    Icon,
    CDropdownAction,
    COptions,
  },
  name: "ShelfAlbumItem",
  props: {
    album: {
      type: Object,
      required: true,
    },
  },
  filters: {
    formatDate(data) {
      return Intl.DateTimeFormat("pt-br", { dateStyle: "short" }).format(
        Date.parse(data)
      );
    },
  },
  methods: {
    async publish() {
      let result = await this.$s.album.publishAlbum(this.album.id);
      if (result.data == "OK") {
        this.album.is_published = true;
        this.album.updated_at = new Date().toISOString();
        this.showSuccessToast("Álbum publicado com sucesso.");
      }
    },
    async unpublish() {
      let result = await this.$s.album.unpublishAlbum(this.album.id);
      if (result.data == "OK") {
        this.album.is_published = false;
        this.album.updated_at = new Date().toISOString();
        this.showSuccessToast("Álbum despublicado com sucesso.");
      }
    },
    showSuccessToast(message) {
      this.$publish(events.SHOW_TOAST, {
        type: "success",
        text: message,
        timeout: 3000,
        width: "100%",
        position: "top",
      });
    },
  },
};
</script>
<style scoped>
.album-item {
  box-shadow: 0px 4px 16px rgba(34, 34, 34, 0.02);
  background-color: var(--c-color-white);
  border-radius: 4px;
  border: 1px solid var(--c-color-gray-100);
}
</style>