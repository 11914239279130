<template>
  <div>
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <div class="ui-p-6 ui-text-left">
        <c-link
          class="
            ui-flex ui-text-left ui-p-0 ui-no-underline
            c-text-b2
            ui-mb-2
            c-text-gray-400
          "
          @click="cancel()"
        >
          <icon
            name="ArrowLeft"
            color="var(c-text-gray-400)"
            class="--icon-left"
          />
          Voltar para Lista de estantes
        </c-link>
        <div class="c-text-h2" v-if="id">Edição da estante: {{ title }}</div>
        <div class="c-text-h2" v-else>Criar nova estante</div>
        <div class="ui-py-6 ui-px-4 ui-my-8 container-card">
          <validation-input
            label="Insira o nome da estante"
            v-model="title"
            rules="title"
          />
        </div>
        <div class="c-row">
          <div class="c-col">
            <p class="c-text-h3 ui-my-2">Lista de Álbuns</p>
            <div class="c-text-b3 c-text-gray-300 ui-mb-4">
              A lista abaixo apresenta os 20 primeiros resultados, ou mais
              recentes.
            </div>
            <div class="ui-p-2">
              <c-search-bar
                placeholder="Busque pelo nome do álbum"
                v-model="filter"
                @input="debounceSearch"
                @search="fetchAlbums"
              />
            </div>
            <div class="ui-p-4 container-card">
              <div v-if="loadingAlbums" class="scroll-container">
                <div
                  v-for="(album, index) in availableAlbums"
                  :key="index"
                  class="ui-px-6 ui-py-4"
                >
                  <div class="ui-mb-2 ui-flex">
                    <c-skeleton-circle size="26px" class="ui-mr-2" />
                    <c-skeleton-block width="80%" />
                  </div>
                  <div class="ui-flex ui-pl-4">
                    <c-skeleton-circle size="20px" class="ui-mr-2" />
                    <c-skeleton-block height="20px" width="60%" />
                  </div>
                </div>
              </div>
              <div v-else class="scroll-container">
                <draggable :list="availableAlbums" group="albums">
                  <shelf-album-item
                    v-for="album in availableAlbums"
                    :key="album.id"
                    :album="album"
                  />
                </draggable>
              </div>
            </div>
          </div>

          <div class="c-col">
            <p class="c-text-h3 ui-my-2">Estante</p>
            <div class="c-text-b3 c-text-gray-300 ui-mb-4">
              Álbuns que não foram publicados não aparecem aos alunos!
            </div>
            <div class="container-card ui-p-4">
              <validation-provider rules="selectedAlbums" v-slot="{ errors }">
                <input v-model="selectedAlbums" type="hidden" />
                <div
                  class="new-shelf ui-mb-1"
                  :class="errors.length > 0 ? 'error' : ''"
                >
                  <icon
                    v-show="errors.length > 0"
                    name="error"
                    filled
                    class="error-icon"
                    color="var(--c-color-red-400)"
                  />
                  <draggable
                    class="scroll-container"
                    :list="selectedAlbums"
                    group="albums"
                    @add="validate"
                  >
                    <shelf-album-item
                      v-for="album in selectedAlbums"
                      :key="album.id"
                      :album="album"
                    />
                    <div
                      class="drop-area-message"
                      v-if="selectedAlbums.length == 0"
                    >
                      <span class="c-text-input c-text-gray-300 ui-p-8"
                        >Arraste e solte os álbuns aqui e ordene-os como
                        desejar</span
                      >
                    </div>
                  </draggable>
                </div>
                <div class="ui-text-left c-text-b3 c-text-red-400 ui-pb-3">
                  {{ errors[0] }}
                </div>
              </validation-provider>
              <c-button
                full
                :disabled="selectedAlbums.length == 0"
                @click="clearShelfDialog = true"
                >Limpar estante</c-button
              >
            </div>
          </div>
        </div>
      </div>
      <actions-footer :cancelRoute="'/'" @action-click="handleActionClick">
        <template v-slot:actions>
          <c-button class="ui-mr-4" outline @click="save(handleSubmit)"
            >Somente salvar</c-button
          >
          <c-button @click="confirmSaveAndNext(handleSubmit)"
            >Salvar e ir para editor da home
            <icon name="next" outline class="c-icon--right"
          /></c-button>
        </template>
      </actions-footer>
      <modal :show="clearShelfDialog" @close="clearShelfDialog = false">
        <template v-slot:title
          >Deseja remover todos os álbuns desta estante?</template
        >
        <template v-slot:actions>
          <div class="ui-flex">
            <div class="ui-flex-1">
              <c-link
                class="ui-w-full ui-h-full c-text-gray-300"
                component="button"
                @click="clearShelfDialog = false"
                >Não, cancelar</c-link
              >
            </div>
            <div class="ui-flex-1">
              <c-button
                class="ui-w-full"
                color="primary"
                @click="
                  clearAlbums();
                  clearShelfDialog = false;
                "
                >Sim, remover</c-button
              >
            </div>
          </div>
        </template>
      </modal>
      <modal
        :show="confirmGoToHomeEditorDialog"
        @close="confirmGoToHomeEditorDialog = false"
      >
        <template v-slot:title
          >Você possui álbuns não publicados na estante, deseja ir para o editor
          mesmo assim?</template
        >
        <template v-slot:description>
          Álbuns não publicados não aparecem aos alunos, para publicar cada
          álbum é só clicar no menu
          <icon
            name="kebab"
            class="ui-inline"
            color="var(--c-color-gray-400)"
            width="16"
          />
        </template>
        <template v-slot:actions>
          <div class="ui-flex">
            <div class="ui-flex-1">
              <c-link
                class="ui-w-full ui-h-full c-text-gray-300"
                component="button"
                @click="confirmGoToHomeEditorDialog = false"
                >Não, continuar nesta página</c-link
              >
            </div>
            <div class="ui-flex-1">
              <c-button
                class="ui-w-full"
                color="primary"
                @click="
                  saveAndNext(handleSubmit);
                  confirmGoToHomeEditorDialog = false;
                "
                >Sim, salvar e ir para editor</c-button
              >
            </div>
          </div>
        </template>
      </modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  Icon,
  CSearchBar,
  CButton,
  CSkeletonBlock,
  CSkeletonCircle,
  CLink,
} from "@estrategiahq/coruja-web-ui";
import draggable from "vuedraggable";
import { debounce } from "lodash";
import ValidationInput from "../../components/ValidationInput.vue";
import ShelfAlbumItem from "../../components/ShelfAlbumItem.vue";
import ActionsFooter from "../../components/ActionsFooter.vue";
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import Modal from "../../components/Modal.vue";

const loadingAlbumItems = Array.from({ length: 20 }).map(() => ({}));

export default {
  components: {
    draggable,
    Icon,
    ValidationInput,
    ValidationObserver,
    CSearchBar,
    CButton,
    CLink,
    ShelfAlbumItem,
    ActionsFooter,
    CSkeletonBlock,
    CSkeletonCircle,
    ValidationProvider,
    Modal,
  },
  data() {
    return {
      filter: "",
      title: "",
      duplicateTitle: null,
      id: null,
      loadingAlbums: true,
      albums: loadingAlbumItems,
      selectedAlbums: [],
      clearShelfDialog: false,
      confirmGoToHomeEditorDialog: false,
    };
  },
  created() {
    this.id = this.$route.params.id || null;
  },
  mounted() {
    extend("title", {
      ...required,
      message:
        "O título é obrigatório e deve conter no mínimo 3 e no máximo 250 caracteres",
    });
    extend("title", (value) => {
      if (value === this.duplicateTitle) {
        return "Título com este nome já existe! Insira outro com no mínimo 3 e no máximo 250 caracteres";
      }
      if (value.length < 3 || value.length > 250) {
        return "O título é obrigatório e deve conter no mínimo 3 e no máximo 250 caracteres";
      }
      return true;
    });
    extend("selectedAlbums", {
      ...required,
      message: "É obrigatório arrastar ao menos 1 álbum nesta estante",
    });
    extend("selectedAlbums", (value) => {
      if (value.length < 1) {
        return "É obrigatório arrastar ao menos 1 álbum nesta estante";
      }
      return true;
    });
    if (this.id) {
      this.fetchShelf(this.id);
    }
    this.fetchAlbums();
  },
  computed: {
    isButtonDisabled() {
      return this.title.length < 5;
    },
    availableAlbums() {
      const selectedAlbumsIds =
        (this.selectedAlbums && this.selectedAlbums.map((a) => a.id)) || [];
      return this.albums.filter((a) => selectedAlbumsIds.indexOf(a.id) === -1);
    },
    unpublishedAlbumsOnShelf() {
      return this.selectedAlbums.filter((a) => !a.is_published);
    },
  },
  methods: {
    clearAlbums() {
      this.selectedAlbums = [];
    },
    async fetchShelf(id) {
      let result = await this.$s.shelf.fetchShelf(id);
      this.title = result.data.title;
      this.selectedAlbums = result.data.albums || [];
    },
    async createOrUpdateShelf() {
      const data = {
        title: this.title,
        albums: this.selectedAlbums.map((album) => ({ id: album.id })),
      };
      if (this.id) {
        await this.$s.shelf.updateShelf(this.id, data);
      } else {
        await this.$s.shelf.createShelf(data);
      }
    },
    async save(handleSubmit) {
      await handleSubmit(async () => {
        try {
          await this.createOrUpdateShelf();
          this.showToast("Estante salva com sucesso");
          this.$router.push({ name: "shelves" });
        } catch (error) {
          if (error.response?.data?.error?.Tag == "DUPLICATE_KEY") {
            this.duplicateTitle = this.title;
            this.$refs.form.validate();
          }
          this.showToast("Falha ao salvar estante", "error");
        }
      });
    },
    async confirmSaveAndNext(handleSubmit) {
      if (this.unpublishedAlbumsOnShelf.length == 0) {
        await this.saveAndNext(handleSubmit);
        return;
      }
      await handleSubmit(() => {
        this.confirmGoToHomeEditorDialog = true;
      });
    },
    async saveAndNext(handleSubmit) {
      await handleSubmit(async () => {
        try {
          await this.createOrUpdateShelf();
          this.showToast("Estante salva com sucesso");
          this.$router.push({ name: "home" });
        } catch (error) {
          if (error.response?.data?.error?.Tag == "DUPLICATE_KEY") {
            this.duplicateTitle = this.title;
            this.$refs.form.validate();
          }
          this.showToast("Falha ao salvar estante", "error");
        }
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    async showToast(message, type = "success") {
      this.$emit("toast", {
        text: message,
        type: type,
        position: "top",
        timeout: 3000,
      });
    },
    debounceSearch: debounce(function (input) {
      if (input.length > 3) this.fetchAlbums();
    }, 500),
    async fetchAlbums() {
      this.loadingAlbums = true;
      let search = `?page=1&per_page=20`;
      search += !this.filter.length
        ? ""
        : `&search=(title[icontains]:'${this.filter}')`;

      let result = await this.$s.album.fetchAlbums(search);
      this.albums = result.data.map((item) => ({
        ...item,
        created_at: Intl.DateTimeFormat("pt-br", { dateStyle: "short" }).format(
          Date.parse(item.created_at)
        ),
      }));
      this.loadingAlbums = false;
    },
    handleActionClick(type) {
      const actions = {
        CANCEL: () =>
          window.history.length > 1
            ? this.$router.go(-1)
            : this.$router.push({ name: "shelves" }),
      };
      return actions[type] && actions[type]();
    },
    cancel() {
      this.handleActionClick("CANCEL");
    },
  },
};
</script>

<style scoped>
.new-shelf {
  border: 2px dashed #cccccc;
  border-radius: 4px;
  padding: 8px;
  position: relative;
}

.new-shelf.error {
  border-color: var(--c-color-red-400);
}

.new-shelf .error-icon {
  position: absolute;
  right: 8px;
}

.new-shelf .scroll-container {
  height: 412px;
}

.scroll-container {
  height: 440px;
  max-width: 100%;
  overflow-y: auto;
}

.drop-area-message {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.container-card {
  box-shadow: 0px 4px 16px rgba(34, 34, 34, 0.02);
  background-color: var(--c-color-white);
  border-radius: 8px;
}
</style>
